<template>
  <div class="page">
    <TakeDownContent
      content-name="collection"
      v-if="isCollectionRestrictedInLocation"
    ></TakeDownContent>
    <template v-else>
      <div
        v-if="collection?.descriptionHtml"
        class="container"
        style="text-align: justify"
        v-html="collection?.descriptionHtml"
      ></div>
      <div v-else class="container">
        <PageHeader
          :loading="!collection?.title"
          :title="collection?.title"
          :breadcrumbItems="breadcrumbItems"
        />
      </div>

      <div
        v-if="pageType == PAGE_TYPE_COLLECTION"
        class="container mt-sm result-and-filter"
        :class="{ 'flex-column': newFilterVersion }"
      >
        <client-only>
          <p class="m-0">
            Showing results
            <span v-if="collection?.title">for "{{ collection?.title }}"</span>
          </p>
        </client-only>

        <CollectionSortAndFilter
          :class="{ 'mt-sm mb-sm': newFilterVersion }"
          :filterSections="filterSections"
          :pageType="pageType"
          :collectionHandle="collectionHandle"
        ></CollectionSortAndFilter>
      </div>
      <div v-if="pageType == PAGE_TYPE_COLLECTION" class="container">
        <SelectedFilters :horizontal-scroll="newFilterVersion" />
      </div>

      <div class="collection-page" v-if="pageType == PAGE_TYPE_COLLECTION">
        <div class="container">
          <div class="page__body">
            <div class="error" v-if="error">
              {{ error }}
            </div>
            <div
              class="not-found"
              v-else-if="collectionStore.isCollectionNotFound"
            >
              Not found
            </div>
            <section v-else>
              <ProductGrid
                :lazyloadStartIndex="10"
                v-if="products.length"
                v-show="products.length && !loading"
                :products="products"
                :isProductListTM="true"
                :isImagesSlider="isImagesSlider"
                showWishlistIcon
              />
              <ProductGrid
                v-show="!products.length || loading"
                :products="placeholderProducts"
                :isProductListTM="true"
                :isImagesSlider="isImagesSlider"
              />

              <div class="pagination" v-if="hasPreviousPage || hasNextPage">
                <ul class="pagination__list">
                  <li
                    class="pagination__item pagination__arrow"
                    :class="{
                      'pagination__item--disabled': !hasPreviousPage,
                    }"
                    @click="onPageChange(-1)"
                  >
                    <img
                      :src="DoubleLeftIcon"
                      alt="left arrow"
                      width="48"
                      height="48"
                    />
                    Prev
                  </li>
                  <li class="pagination__item pagination__ellipsis">|</li>
                  <li
                    class="pagination__item pagination__arrow"
                    :class="{
                      'pagination__item--disabled': !hasNextPage,
                    }"
                    @click="onPageChange(1)"
                  >
                    Next
                    <img
                      :src="DoubleRightIcon"
                      alt="right arrow"
                      width="48"
                      height="48"
                    />
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div v-else class="container">
        <SearchResult
          :isImagesSlider="isImagesSlider"
          :collectionTitle="collection?.title"
        ></SearchResult>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import PageHeader from '@/components/PageHeader.vue'
import {
  onMounted,
  onServerPrefetch,
  onUnmounted,
  watch,
  computed,
  useSSRContext,
  ref,
  defineAsyncComponent,
} from 'vue'
import ProductGrid from '@/components/product/ProductGrid.vue'
import SearchResult from '@/pages/search/SearchResult.vue'
import SelectedFilters from '@/components/collection/SelectedFilters.vue'
import LocationService, { UserLocation } from '@/services/location'
import useSettingStore from '@/store/setting'
import useSearchStore from '@/store/search'
import {
  buildCollectionUrl,
  getRawIdFromShopifyId,
  isCollectionHasTag,
  isRestrictedInLocation,
} from '@/utils/product'
import { usePage } from '@/composables/page'
import { useMetaTag } from '@/composables/metaTag'
import CollectionSortAndFilter from '@/components/collection/CollectionSortAndFilter.vue'
import { useCollection } from './composables/collection'
import { FilterSection } from '@/types'
import DoubleLeftIcon from '@/assets/images/icons/double-left-arrows.png'
import DoubleRightIcon from '@/assets/images/icons/double-right-arrows.png'
import { isOnServer } from '@/utils/ssr'
import { appendToHead } from '@/composables/appendToHead'
import {
  SSR_CONTEXT_APPEND_TO_HEAD_META_TAGS,
  SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_META_TAGS,
  SSR_CONTEXT_APPEND_TO_HEAD_JSON_LD,
  EVENT_TRACKING_LOCATION_BLOCKED,
  EVENT_TRACKING_PAGE_COLLECTION_VIEWED,
  GOOGLE_BUSINESS_VERTICAL,
  PRODUCT_ID_TRACKING_BASE_ONE_PRODUCT_VARIANT_SKU,
} from '@/utils/constants'
import { MIXPANEL_DESTINATION, destinationIntegrations } from '@/utils'
import Logger from '@/services/log'
import CacheService from '@/services/cache'
import useCollectionStore from '@/store/collection'
import Analytics from '@/services/analytics'
import { useRoute } from 'vue-router'

const TakeDownContent = defineAsyncComponent(
  () => import('@/components/TakeDownContent.vue')
)

const route = useRoute()
const collectionStore = useCollectionStore()
const settingStore = useSettingStore()
const searchStore = useSearchStore()
const { setPageTitle, setPageDescription } = usePage()
const { renderImageMetaTags } = useMetaTag()
const PAGE_TYPE_SEARCH = 'search'
const PAGE_TYPE_COLLECTION = 'collection'
const isCollectionRestrictedInLocation = ref(false)

const newFilterVersion = computed(
  () => settingStore.pages?.general?.filterVersion?.toUpperCase() == 'V2'
)

const collection = computed(() => collectionStore.collection)
const placeholderProducts = Array(12).fill(undefined)
const pageType = computed(() => {
  return settingStore.tmProductTags.some((tag) =>
    isCollectionHasTag(collection.value || undefined, tag)
  )
    ? PAGE_TYPE_COLLECTION
    : PAGE_TYPE_SEARCH
})

const products = computed(() => {
  const collectionProduct = collection.value?.products ?? []
  const productsWithOutUtmProducts = collectionProduct.filter((product) =>
    collectionStore.utmProducts.every(
      (utmProduct) => product?.id != utmProduct.id
    )
  )
  return [...collectionStore.utmProducts, ...productsWithOutUtmProducts]
})

// get filter sections based on page type
const filterSections = computed<FilterSection[]>(() => {
  const filterSections: FilterSection[] = []
  if (pageType.value === PAGE_TYPE_COLLECTION) {
    // Use all current product type as filter options
    if (collection.value && collection.value.__products__filters) {
      collection.value.__products__filters.forEach((filter) => {
        const filterSection: FilterSection = {
          attribute: filter.id.split('.').pop() || '',
          title: filter.label,
          buckets: [],
          type: filter.type,
          alwaysExpanded:
            (collection.value?.__products__filters?.length || 0) <= 1,
        }

        filter.values.forEach((filterValue) => {
          filterSection.buckets.push({
            count: filterValue.count,
            value: filterValue.label,
            input: filterValue.input,
          })
        })

        filterSections.push(filterSection)
      })
    }
    return filterSections
  }
  return searchStore.searchResults?.facets || []
})

const breadcrumbItems = computed(() => {
  if (!collection.value) return []
  return [
    {
      url: buildCollectionUrl(collection.value.handle),
      name: collection.value.title,
    },
  ]
})

const isTrackingProductIdBaseOnVariantSku = computed(() => {
  return (
    settingStore.shop?.productTrackingProductIdBaseOn ==
    PRODUCT_ID_TRACKING_BASE_ONE_PRODUCT_VARIANT_SKU
  )
})

const isImagesSlider = computed(
  () => settingStore.pages?.general?.collectionProductImageSlider || false
)

const {
  collectionParams,
  fetchData,
  error,
  loading,
  resetVariables,
  collectionHandle,
  hasNextPage,
  hasPreviousPage,
  loadNextPage,
  loadPreviousPage,
  renderItemListJsonLd,
} = useCollection()

const ssrContext = isOnServer && useSSRContext()

onServerPrefetch(async () => {
  await fetchData()
  // reset variables to prevent hydration mismatch in case can not fetch data.
  // we will try to load again on the client
  resetVariables()
  if (collection.value) {
    setPageTitle(collection.value.title)
  } else {
    return
  }
  if (collection.value?.description) {
    setPageDescription(collection.value.description)
  }
  injectMetaTagsIntoHeadTag()
  injectItemListJsonLdIntoHeadTag()
})

onMounted(async () => {
  Logger.log('Colleciton mounted', collectionStore.collection, 3)
  if (collectionStore.isCollectionNotFound) return

  if (!collectionStore.collection) {
    await fetchData()
  } else {
    if (collectionStore.collection.__cacheKey) {
      Logger.log(
        'Collection Page',
        'Set collection from server side to cache',
        3
      )
      CacheService.instance?.set(
        collectionStore.collection.__cacheKey,
        JSON.parse(JSON.stringify(collectionStore.collection))
      )
    }
  }

  // after fetching collection, check it is not found again
  if (collectionStore.isCollectionNotFound) return

  // check if collection is restricted in this location
  LocationService.onLocationReady((location) => {
    // Check if the collection is restricted on this location, show take down content
    if (!collectionHandle.value || !location) return
    const restrictedCollections =
      settingStore.locationRestricted?.TMRestricted.collections

    if (
      isRestrictedInLocation(
        collectionHandle.value,
        restrictedCollections,
        location
      )
    ) {
      isCollectionRestrictedInLocation.value = true

      trackingLocationBlockedCollection(location)
    }
  })

  watch(
    collectionParams,
    (newValue, oldValue) => {
      if (
        collectionStore.isCollectionNotFound ||
        !oldValue ||
        !newValue ||
        !newValue.handle ||
        // do not fetch collection incase route changed
        newValue.handle != oldValue.handle ||
        JSON.stringify(oldValue) == JSON.stringify(newValue)
      )
        return
      if (pageType.value === PAGE_TYPE_SEARCH) return
      Logger.log('Collection params changed', collectionParams, 3)
      fetchData()
      collectionStore.setUtmProductsOnCollection([])
    },
    { deep: true }
  )
  trackingPageCollectionViewed()
})

onUnmounted(() => {
  Logger.log('Collection page unmounted', pageType.value, 3)
  collectionStore.collection = null
  collectionStore.isCollectionNotFound = false
  collectionStore.setUtmProductsOnCollection([])
})

function onPageChange(change: number) {
  // scroll to top after update loading state for smooth animation on safari
  requestAnimationFrame(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
  if (change < 0) {
    loadPreviousPage()
  } else {
    loadNextPage()
  }
}

function trackingLocationBlockedCollection(location: UserLocation | null) {
  if (!location) return

  Analytics.track(
    EVENT_TRACKING_LOCATION_BLOCKED,
    {
      path: route.path,
      user_ip: location.ip,
      country_code: location.country_code,
      country_name: location.country_name,
      region_code: location.region_code,
      region_name: location.region_name,
      city: location.city,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}

function trackingPageCollectionViewed() {
  if (!loading.value && pageType.value == PAGE_TYPE_COLLECTION) {
    Analytics.track(EVENT_TRACKING_PAGE_COLLECTION_VIEWED, {
      name: collection.value?.title,
      handle: collectionHandle.value,
      param: route.fullPath.split('?')[1] || '',
      content_type: 'product_group',
      items: products.value?.length
        ? products.value?.map((item) => ({
            id: isTrackingProductIdBaseOnVariantSku.value
              ? item?.variants?.length
                ? item.variants[0].sku
                : ''
              : +getRawIdFromShopifyId(item?.id),
            name: item?.title,
            price: item?.variants?.length ? +item.variants[0].price! : 0,
            google_business_vertical:
              settingStore.shop?.googleBusinessVertical ||
              GOOGLE_BUSINESS_VERTICAL,
          }))
        : [],
    })
  }
}

function injectMetaTagsIntoHeadTag() {
  // inject meta tags into head tag
  if (
    !ssrContext ||
    pageType.value != PAGE_TYPE_COLLECTION ||
    !collection.value
  )
    return

  let collectionMetaTags = `<meta property="og:type" content="product.group" />`

  if (pageType.value == PAGE_TYPE_COLLECTION) {
    collectionMetaTags += `<meta name="robots" content="noindex">`
  }

  appendToHead(
    collectionMetaTags,
    SSR_CONTEXT_APPEND_TO_HEAD_META_TAGS,
    ssrContext
  )

  let image = undefined

  if (collection.value.image) {
    image = collection.value.image
  } else {
    const firstProduct =
      collection.value.products && collection.value.products[0]
    const fisrtProductImage = firstProduct?.images && firstProduct.images[0]

    image = fisrtProductImage
  }

  const collectionImageMetaTags = renderImageMetaTags(image)
  appendToHead(
    collectionImageMetaTags,
    SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_META_TAGS,
    ssrContext
  )
}

function injectItemListJsonLdIntoHeadTag() {
  if (
    pageType.value != PAGE_TYPE_COLLECTION ||
    !ssrContext ||
    !collection.value ||
    !collection.value.products?.length
  )
    return
  const itemListJsonLdScript = renderItemListJsonLd(collection.value.products)

  appendToHead(
    itemListJsonLdScript,
    SSR_CONTEXT_APPEND_TO_HEAD_JSON_LD,
    ssrContext
  )
}
</script>

<style lang="scss">
.collection-page {
  .pagination__item {
    gap: 2px;
  }
}
.result-and-filter {
  @include media-md-up {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  p {
    text-align: center;
  }
}

.flex-column {
  flex-direction: column;
}

.collection-video-desktop {
  width: 100%;
  display: none;
  @include media-md-up {
    display: block;
  }
}

.collection-video-mobile {
  width: 100%;
  display: block;
  @include media-md-up {
    display: none;
  }
}
video.collection-video-desktop {
  aspect-ratio: 3.1199;
}
video.collection-video-mobile {
  aspect-ratio: 1;
}
</style>
