import { useFetchData } from '@/composables/fetchData'
import {
  ProductCollectionSortKey,
  ProductCollectionSortKeyBestSelling,
  ProductCollectionSortKeyCreated,
  ProductCollectionSortKeyDefault,
  ProductCollectionSortKeyPrice,
} from '@/provider/type'
import {
  Collection,
  CollectionAndProducts,
  CollectionAndProduct,
  Image,
  NullOrType,
  Product,
  ProductVariant,
} from '@/types'
import {
  COLLECTION_AFTER_QUERY_STRING,
  COLLECTION_BEFORE_QUERY_STRING,
  COLLECTION_RESTRICTED_BY_PRODUCT_TYPE,
  minimumDelay,
  SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX,
  SORT_BY_QUERY_STRING,
  SORT_ORDER_QUERY_STRING,
  QUERY_STRING_PRODUCT_IDS,
  QUERY_STRING_PRODUCT_HANDLE,
  PRODUCT_ID_PREFIX,
} from '@/utils'
import { CURRENCY, QUERY_STRING_NO_CACHE } from '@/utils/constants'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import useUpdateRouter from '@/composables/router'
import Logger from '@/services/log'
import useCollectionStore, {
  GetCollectionByHandleParams,
} from '@/store/collection'

export function useCollection() {
  const collectionStore = useCollectionStore()
  const route = useRoute()
  const { updateQueryString } = useUpdateRouter()

  const sortOptionMapping: Array<{
    sortKey: ProductCollectionSortKey
    reverse: boolean
    sortBy?: string
    sortOrder?: string
  }> = [
    {
      sortKey: ProductCollectionSortKeyDefault,
      reverse: false,
    },
    {
      sortBy: 'created',
      sortOrder: 'desc',
      sortKey: ProductCollectionSortKeyCreated,
      reverse: false,
    },
    {
      sortBy: 'created',
      sortOrder: 'asc',
      sortKey: ProductCollectionSortKeyCreated,
      reverse: true,
    },
    {
      sortBy: 'price',
      sortOrder: 'asc',
      sortKey: ProductCollectionSortKeyPrice,
      reverse: false,
    },
    {
      sortBy: 'price',
      sortOrder: 'desc',
      sortKey: ProductCollectionSortKeyPrice,
      reverse: true,
    },
    {
      sortBy: 'sales_amount',
      sortOrder: 'desc',
      sortKey: ProductCollectionSortKeyBestSelling,
      reverse: false,
    },
  ]

  const hasNextPage = computed(
    () =>
      collectionStore.collection?.__products__pageInfo?.hasNextPage &&
      collectionStore.collection.__products__pageInfo.endCursor
  )
  const hasPreviousPage = computed(
    () =>
      collectionStore.collection?.__products__pageInfo?.hasPreviousPage &&
      collectionStore.collection.__products__pageInfo.startCursor
  )

  const collectionHandle = computed(() => {
    return route.params.collectionHandle as string
  })

  const collectionParams = computed(() => {
    const params: GetCollectionByHandleParams = {
      handle: collectionHandle.value,
      isForceLoadNewData: !!route.query[QUERY_STRING_NO_CACHE],
      filters: [],
    }

    let productTypes =
      route.query[
        SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX +
          COLLECTION_RESTRICTED_BY_PRODUCT_TYPE
      ]

    if (productTypes) {
      if (!Array.isArray(productTypes)) {
        productTypes = [productTypes]
      }
      productTypes.forEach((productType) => {
        params.filters?.push({
          productType: productType || '',
        })
      })
    }

    // priority paging by after higher than before
    const after = route.query[COLLECTION_AFTER_QUERY_STRING] as string
    if (after) {
      params.after = after
    } else {
      const before = route.query[COLLECTION_BEFORE_QUERY_STRING] as string
      if (before) {
        params.before = before
      }
    }

    const sortBy = route.query[SORT_BY_QUERY_STRING]
    const sortOrder = route.query[SORT_ORDER_QUERY_STRING]
    if (sortBy && sortOrder) {
      const sortOption = sortOptionMapping.find((elm) => {
        return elm.sortOrder === sortOrder && elm.sortBy === sortBy
      })
      if (sortOption) {
        params.sortKey = sortOption.sortKey
        params.reverse = sortOption.reverse
      }
    }

    const utmProductIds = route.query[QUERY_STRING_PRODUCT_IDS] as string
    if (utmProductIds) {
      const productIds = utmProductIds
        .split('-')
        .map((id) => `${PRODUCT_ID_PREFIX}/${id}`)
      params.productIds = productIds
    }

    const utmProductHandle = route.query[QUERY_STRING_PRODUCT_HANDLE] as string
    if (utmProductHandle) {
      params.productHandle = utmProductHandle
    }

    return params
  })

  async function fetchCollection() {
    Logger.log('Collection fetching', JSON.stringify(collectionParams.value), 3)
    if (!collectionHandle.value) {
      throw new Error('Invalid handle')
    }
    return collectionStore.fetchCollection(collectionParams.value)
  }

  const { fetchData, error, loading, resetVariables } = useFetchData({
    // set minimum delay for this API to make the loading and scroll to top smoother
    fetchDataFunction: minimumDelay(fetchCollection),
    checkNotFoundFunction: checkNotFound,
    setNotFoundFunction: setNotFound,
  })

  function checkNotFound(
    collection: NullOrType<
      Collection | CollectionAndProducts | CollectionAndProduct
    >
  ) {
    return !collection
  }

  function setNotFound(isNotFound: boolean) {
    collectionStore.isCollectionNotFound = isNotFound
  }

  function loadNextPage() {
    if (!hasNextPage.value) return
    const params = {
      [COLLECTION_AFTER_QUERY_STRING]:
        collectionStore.collection!.__products__pageInfo!.endCursor,
      [COLLECTION_BEFORE_QUERY_STRING]: undefined,
      [QUERY_STRING_PRODUCT_IDS]: undefined,
      [QUERY_STRING_PRODUCT_HANDLE]: undefined,
    }
    updateQueryString(params)
  }
  function loadPreviousPage() {
    if (!hasPreviousPage.value) return
    const params = {
      [COLLECTION_AFTER_QUERY_STRING]: undefined,
      [COLLECTION_BEFORE_QUERY_STRING]:
        collectionStore.collection!.__products__pageInfo!.startCursor,
      [QUERY_STRING_PRODUCT_IDS]: undefined,
      [QUERY_STRING_PRODUCT_HANDLE]: undefined,
    }
    updateQueryString(params)
  }

  function renderItemListJsonLd(products: NullOrType<Product>[]) {
    const itemListJsonLd = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      numberOfItems: products.length + '',
      itemListElement: products.map((product: NullOrType<Product>) => {
        if (!product) return {}
        return {
          '@type': 'Product',
          name: product.title,
          image: product.images?.map((image: Image) => image.src),
          description: product.description,
          offers: product.variants?.map((item: ProductVariant) => ({
            '@type': 'Offer',
            priceCurrency: CURRENCY,
            price: item.price + '',
          })),
        }
      }),
    })

    const itemListJsonLdScript = `<script type="application/ld+json">${itemListJsonLd}<\/script>`

    return itemListJsonLdScript
  }

  return {
    collectionParams,
    fetchData,
    error,
    loading,
    resetVariables,
    collectionHandle,
    hasNextPage,
    hasPreviousPage,
    loadNextPage,
    loadPreviousPage,
    renderItemListJsonLd,
  }
}
